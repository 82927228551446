import React from 'react'
import { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import './Hero.css'
// import {ethers, BigNumber} from 'ethers'
import {ethers,BigNumber} from 'ethers';
import LeprechaunNFT from '.././LeprechaunNFT.json'


const LeprechaunAddress='0x00780064694a6D5c03472b70F3c4b8B6c1038825';



const Hero = ({accounts,setAccounts}) => {
    const [mintAmount,setMintAmount]=useState(1);
    const isConnected= Boolean(accounts[0]);
    
    const handleDecrement=()=>{
        if(mintAmount <= 1) return;
        setMintAmount(mintAmount - 1)
    }
    const handleIncrement=()=>{
        if(mintAmount >=3) return;
        setMintAmount(mintAmount + 1)
    }

    async function handleMint(){
        if(window.ethereum){
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract= new ethers.Contract(
                LeprechaunAddress,
                LeprechaunNFT.abi,
                signer
            );
            try{
                const response = await contract.mint(BigNumber.from(mintAmount),{
                    value: ethers.utils.parseEther((0.02 * mintAmount).toString())
                });
                console.log('response', response)
            }catch(e){
                console.log(e)
            }
        }
    } 
  return (
    <div className='hero_container'>
        <div className='hero_inner'>
            <h1>Ŵ€ŁĆØΜ€ ŦØ Ł€ƤŘ€ĆĦΔỮŇŦØŴŇ 🌈</h1>
            
            {
                isConnected ? (
                    <div className='mint_container'>
                       <div>
                       <button onClick={handleDecrement}>-</button>
                        <input type='number' value={mintAmount}/>
                        <button onClick={handleIncrement}>+</button>
                       </div>
                        <button
                        onClick={handleMint}
                        >MINT NOW</button>
                    </div>
                    
                ):(
                    <p>Connect Wallet to mint</p>
                )
            }
           
        </div>
       
    </div>
  )
}

export default Hero