import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Slider from './components/Slider';



function App() {
  const[accounts, setAccounts]=useState([])
  return (
    <div className="App">
      <Header accounts={accounts} setAccounts={setAccounts}/>
      <Hero accounts={accounts} setAccounts={setAccounts}/>
      <Slider/>
    </div>
  );
}

export default App;
