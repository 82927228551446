import React from 'react'
import './Slider.css'


const Slider = () => {
  return (
    <div className='slider'>
        <div className='slide-track'>
            <div className='slide'>
                <img 
                alt='art'
                src='/image1.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='/image2.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='/image3.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image4.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image5.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image6.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image7.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image5.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image4.jfif'/>
            </div>

            {/* double 9 slides */}

            <div className='slide'>
                <img 
                alt='art'
                src='/image1.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='/image2.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='/image3.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image4.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image5.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image6.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image7.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image5.jfif'/>
            </div>
            <div className='slide'>
                <img 
                alt='art'
                src='image4.jfif'/>
            </div>
        </div>
    </div>
  )
}

export default Slider