import React, { useState } from 'react'
import {FaBars, FaDiscord, FaTwitter} from 'react-icons/fa'
import './Header.css';


const Header = ({setAccounts, accounts}) => {
    const isConnected= Boolean(accounts[0]);
    const[show, setShow]= useState(false)

    async function connectAccount(){
        if(window.ethereum){
            const accounts= await window.ethereum.request({
                method:'eth_requestAccounts'
            });
            setAccounts(accounts)
        }
    }
  return (
    <div className='header_container'>
        <div className='header_inner'>
            <div
            className='logo'
            >Ł€ƤŘ€ĆĦΔỮŇŦØŴŇ_ŴŦ₣☘️
            </div>

            <div className='right_nav'>
                {isConnected?(
                    <p>Connected</p>
                ):(
                <button
                onClick={connectAccount}
            className='connect_wallet'
            >
                Connect Wallet
            </button>
                )}
            
            <a href='https://twitter.com/Leprechaun_Wtf' className='twitter'>
                <FaTwitter
                size={20}
                />
            </a>
            {/* <div className='discord'>
                <FaDiscord
                size={20}
                />
            </div> */}
           
            </div>
            <div className='hamburger'>
            <FaBars
            onClick={()=>setShow(!show)}
            size={20}
            />
        </div>
        </div>
        {show && 
        
        <div className='mobile_nav'>
        {isConnected?(
                    <p>Connected</p>
                ):(
                <button
                onClick={connectAccount}
            className='connect_wallet'
            >
                Connect Wallet
            </button>
                )}
            <a href='https://twitter.com/Leprechaun_Wtf'>
            <FaTwitter
            style={{marginTop:"10px"}}
                size={20}
                />
            </a>
        </div>
        }
        
    </div>
  )
}

export default Header;